import { Sequence } from "../scene-control/sequence";
import { SceneInfo } from "../store/factories/create-script-store";
import { IAssetsLoaderStore } from "../store/interfaces/assets-loader-store";
import { IBasicGameStore } from "../store/interfaces/basic-game-store";
import { ICommunicationsStore } from "../store/interfaces/communications-store";
import { INarrativeStore } from "../store/interfaces/narrative-store";
import { IPlayerCameraStore } from "../store/interfaces/player-camera-store";
import { IScreenPlayStore } from "../store/interfaces/screen-play-store";
import { IScriptStore } from "../store/interfaces/script-store";
import { ISequencePlayerStore } from "../store/interfaces/sequence-player-store";
import { ISoundtrackStore } from "../store/interfaces/soundtrack-store";
import { IVideoMixerStore } from "../store/interfaces/video-mixer-store";
import { shootBuilder as s } from "./utils";

export const SecondChoiceDecision: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    ISequencePlayerStore &
    IScriptStore &
    INarrativeStore &
    IBasicGameStore &
    IAssetsLoaderStore
> = {
  play(set, get) {
    set({ nextSceneIndex: "second-choice-consequences" });

    get().setContinue(() => {
      if (get().nextShootIndex === 22) {
        get().playSoundtrack("the-mission");
        get().startCommunication("4", {
          message: `terminal_finale_${feedback}`,
        });
        get().shoot(get().nextShootIndex);

        get().setContinue(() => {
          get().playScene(get().nextSceneIndex);
        });
      } else {
        get().shoot(get().nextShootIndex);
      }
    });

    const incidentA = get().gameState.incidentByOrder(0);
    const solutionA = get().gameState.appliedSolution(incidentA.id);

    const incidentB = get().gameState.incidentByOrder(1);
    const solutionB = get().gameState.appliedSolution(incidentB.id);

    const finalSolution = `A${solutionA}_B${solutionB}`;
    const feedback = finalFeedback(finalSolution);

    const videoDir = `decisions${finalSolution}`;
    get().loadVideoChannel("4", {
      videoPath: get().assetUrl(`/assets/videos/_/${videoDir}/video.webm`),
      openingThumbPath: get().assetUrl(`/assets/videos/_/${videoDir}/in.avif`),
      closingThumbPath: get().assetUrl(`/assets/videos/_/${videoDir}/out.avif`),
    });

    get().assignNarrativeChannel("4", {
      message: `finale_${feedback}`,
      picture: "team_intro",
    });

    get().turnUIOff();

    get().playSequence(
      Sequence.fromStages([
        (next) => {
          get().replaceShootList([
            s.addComics(["0:0"]).far().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["0:1"]).inside().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["1:0"]).make(),

            s.addComics(["1:1"]).make(),
            s.addComics(["1:2"]).far().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:3"]).inside().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:4"]).make(),
            s.addComics(["1:5"]).far().make(),
            s.addComics(["2:0"]).make(),

            s.addComics(["2:1"]).far().make(),
            s.addComics(["2:2"]).make(),
            s.addComics(["2:2"]).inside().make(),
            s.addComics(["2:2"]).make(),
            s.addComics(["2:3"]).far().make(),

            s.addComics(["3:0"]).make(),
            s.addComics(["3:1"]).make(),
            s.addComics(["3:1"]).inside().make(),
            s.addComics(["3:1"]).make(),
            s.addComics(["3:2"]).far().make(),
          ]);
          get().shoot(19);
          setTimeout(next, 2500);
        },
        (next) => {
          get().shoot(20);
          get().onVideoEnded("4", next);
        },
        (next) => {
          get().shoot(21);
          get().turnUIOn();
          next();
        },
      ])
    );
  },
};

function finalFeedback(solution: string) {
  if (GOOD_SOLUTIONS.includes(solution)) return "good";
  if (BAD_SOLUTIONS.includes(solution)) return "bad";
  return "random";
}

const GOOD_SOLUTIONS = ["A1_B4", "A2_B4", "A3_B3", "A4_B4"];
const BAD_SOLUTIONS = ["A1_B1", "A2_B1", "A3_B1", "A4_B3"];
