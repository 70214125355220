import { LedspClient } from "ledsp-sdk";
import gameConceptManifest from "../../.ledsp-gc-manifest/fireballs-1.0.0.json";
import useGameQueryParams from "../hooks/useGameQueryParams.hook";

export default function () {
  const { gamePlayInfoId, demoId } = useGameQueryParams();

  if (!gamePlayInfoId && !demoId)
    throw new Error("Missing or invalid gamePlayInfoId or demoId!");

  return LedspClient.getInstance({
    gamePlayInfoId: gamePlayInfoId || demoId,
    environment: process.env.ENV,
    emulate: process.env.ENV === "development",
    gameConceptToEmulate: gameConceptManifest,
    gameEventsMountPointId: "events-debugger",
  });
}
