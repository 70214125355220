import React, { Suspense, useEffect, useState } from "react";
import buildAssetPath from "../utils/buildAssetPath";
import { BoardCanvas } from "./comps/board-canvas";
import { BoardFrame } from "./comps/board-frame";
import { DevTools } from "./comps/dev-tools";
import { useStore } from "./store";
import getLedspClient from "../utils/get-ledsp-client";

export const App: React.FC<AppProps> = (props) => {
  const [isStoreHydrated, setIsStoreHydrated] = useState(false);

  useEffect(() => {
    useStore.persist.setOptions({
      name: `fireballs-${props.gamePlayInfoId}`,
    });

    useStore.persist.rehydrate().then(() => setIsStoreHydrated(true));
  }, [props.gamePlayInfoId]);

  if (!isStoreHydrated) return <span>Hydrating store....</span>;
  return (
    <Game initializer={<Initializer gamePlayInfoId={props.gamePlayInfoId} />} />
  );
};

type AppProps = {
  readonly gamePlayInfoId: string;
};

const Game: React.FC<{ initializer: React.ReactElement }> = (props) => {
  const isInitialized = useStore((s) => s.isInitialized);
  const hasHydrated = useStore((s) => s.hasHydrated);

  if (!isInitialized) return props.initializer;
  if (!hasHydrated) return <span>Loading Game...</span>;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <BoardFrame>
        <BoardCanvas />
      </BoardFrame>
      <DevTools />
      <QuanticoFont />
    </Suspense>
  );
};

const Initializer: React.FC<{ gamePlayInfoId: string }> = (props) => {
  const init = useStore((s) => s.init);

  const [error, setError] = useState<DataFetchingError>(null);

  useEffect(() => {
    console.log(`»» initializing game: «${props.gamePlayInfoId}»`);

    getLedspClient()
      .gamePlayInfo()
      .then((gamePlayInfo) => {
        init({
          assetsBaseUrl: buildAssetPath("/en"),
          ...gamePlayInfo,
        });
      })
      .catch(setError);
  }, [props.gamePlayInfoId]);

  return (
    <>
      {!props.gamePlayInfoId && <span>Missing game play info identifier!</span>}
      {props.gamePlayInfoId && error && <span>{error.message}</span>}
      {!error && <span>Initializing game...</span>}
    </>
  );
};

class DataFetchingError extends Error {
  private _info: any = null;
  private _status: string | number = null;

  static create(message: string, status: string | number, info: any = null) {
    const error = new DataFetchingError(message);
    error._info = info;
    error._status = status;

    return error;
  }

  get info() {
    return this._info;
  }
  get status() {
    return this._status;
  }
}

const LoadingScreen = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Quantico",
      }}
    >
      <div>THE GAME IS LOADING...</div>
    </div>
  );
};

const QuanticoFont = () => {
  const url = useStore((s) => s.assetUrl);
  const styleInnerText = `
    @font-face {
      font-family: 'Quantico';
      src: url('${url(
        "/assets/fonts/Quantico-Bold.woff?family=Quantico-Bold"
      )}');
  `;

  return <style>{styleInnerText}</style>;
};
